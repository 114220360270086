
<template>
    <div class="pager">
        <div class="nav">
            <van-nav-bar :title="$t('financial.nav_title')" left-arrow @click-left="$router.go(-1)" />
        </div>

        <div class="box">
            <div class="box_header">
                <div class="desc">
                    <span>{{ $t('financial.header.desc') }}</span>
                </div>
                <div class="money">
                    <span>{{ info.orderMoney || 0.00 }}</span>
                    <span>USDT</span>
                </div>
                <div class="box_items">
                    <div>
                        <div>
                            <span>{{ info.todayProfit || 0.00 }}</span>
                        </div>
                        <div>
                            <span>{{ $t('financial.header.items.0') }}</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>{{ info.totalProfit || 0.00 }}</span>
                        </div>
                        <div>
                            <span>{{ $t('financial.header.items.1') }}</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>{{ info.orderCount || 0 }}</span>
                        </div>
                        <div>
                            <span>{{ $t('financial.header.items.2') }}</span>
                        </div>
                    </div>
                </div>
                <div class="order" @click="onSubscribeList">
                    <span>{{ $t('financial.header.order') }}</span>
                </div>
            </div>
            <!-- :finished-text="items.length == 0 ? '' : $t('common.nomore')" -->
            <van-list v-model="loading" :finished="finished" 
                :loading-text="$t('jia-zai-zhong')" @load="onLoad">
                <div class="box_list">
                    <div class="cell" v-for="(item, key) in items" :key="key" @click="onPurchase(item)">
                        <div class="top">
                            <img :src="item.icon" />
                            <span class="name">{{ item.name }}</span>
                            <van-button type="primary" size="small">{{ $t('financial.list.button') }}</van-button>
                        </div>
                        <div class="bottom">
                            <div>
                                <span>{{ $t('financial.list.items.0') }}</span>
                                <span>{{ item.min }}~{{ item.max }}</span>
                            </div>
                            <div>
                                <span>{{ $t('financial.list.items.1') }}</span>
                                <span>{{ item.day_rate }}%</span>
                            </div>
                            <div>
                                <span>{{ $t('financial.list.items.2') }}</span>
                                <span>{{ item.days }}{{ $t('financial.list.sufix') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- :description="$t('common.nomore')" -->
                <van-empty v-if="items.length == 0"  />
            </van-list>



        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            finished: false,
            items: [],
            page: 0,
            info: {
                orderCount: "",
                todayProfit: "",
                totalProfit: "",
                orderMoney: "",
            },

        }
    },
    created() {
        this.onLoadData();
    },
    methods: {

        onLoadData() {


            this.$http.post("/home/home/wmpOrderData").then(res => {
                if (res.data.code == 200) {
                    this.info = res.data.data;
                }
            })
        },
        onLoad() {
            this.page++;

            this.$http.post("/home/index/wmpList?page=" + this.page).then(res => {
                this.loading = false;
                if (res.data.code == 200) {
                    if (res.data.data.length == 0) {
                        this.finished = true;
                    }
                    if (this.page == 1) {
                        this.items = res.data.data;
                    } else {
                        this.items.push(...res.data.data);
                    }
                }
            }).catch(err => {
                this.loading = false;
                this.finished = true;
            })
        },
        onPurchase(item) {
            this.$router.push({ path: '/Lockup/Purchase/' + item.id });
        },
        onSubscribeList() {
            this.$router.push({ path: '/Lockup/subscribe' });
        }
    }
}

</script>

<style scoped lang="less">
.pager {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #E3E6EA;
    overflow-y: scroll;
}

.nav {
    position: fixed;
    width: 100vw;
    font-size: 1.12rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
    z-index: 999;
}

.box {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 46px;

    .box_header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100vw;
        color: #fff;
        background: #f0b82d;
        padding: 25px 0 9px 0;

        .desc {
            margin-left: 18px;
            font-size: 15px;
            font-weight: 400;
        }

        .money {
            margin-left: 18px;
            font-size: 30px;
            font-weight: 400;

            span:last-child {
                margin-left: 5px;
                font-size: 20px;
            }
        }

        .box_items {
            padding: 12px 0;
            width: 100vw;
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            background: rgba(255, 255, 255, 0.20);

            div {
                div:first-child {
                    font-size: 16px;
                    font-weight: 600;
                }

                div:last-child {
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }

        .order {
            position: absolute;
            right: 0px;
            top: 41px;
            color: #363636;
            font-size: 15px;
            font-weight: 400;
            background: rgba(255, 255, 255, 0.82);
            padding: 5px 20px;
            border-radius: 16px 0 0 16px;
        }
    }

    .box_list {
        width: 100vw;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding: 0 16px;

        .cell {
            padding: 18px 0;

            .top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 28px;
                    height: 28px;
                }

                .name {
                    color: #333;
                    font-size: 16px;
                    font-weight: 500;
                    flex: 1;
                    margin: 0 14px;
                    text-align: left;
                }

                /deep/.van-button--primary {

                    border-radius: 5px;
                    background: #f0b82d;
                    border: none;
                }


            }

            .bottom {
                margin-top: 22px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    span:first-child {
                        color: #777;
                        font-size: 12px;
                        font-weight: 400;
                    }

                    span:last-child {
                        margin-top: 6px;
                        color: #363636;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }

    }
}
</style>